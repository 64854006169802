<template>
    <div class="page-wrapper">
        <section class="hero_block">
            <div class="data">
                <h1 class="title" data-inview="fadeInUp" data-delay="200"><span v-html="contentIsLoaded ? content.heroSections.titre : ''"></span></h1>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="contentIsLoaded ? content.heroSections.texte : ''"></span>
                </p>
                <a
                    :href="contentIsLoaded ? content.heroSections.boutonUrl[$route.meta.lang] : ''"
                    target="_blank"
                    class="cta regular-text"
                    data-inview="fadeInUp"
                    data-delay="400"
                    >{{ contentIsLoaded ? content.heroSections.bouton.customText : '' }}</a
                >
                <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
            </div>
            <div class="hero_img" data-inview="slideLeft" data-delay="200">
                <img :src="contentIsLoaded ? content.heroSections.image.url : ''" :alt="contentIsLoaded ? content.heroSections.image.titre : ''" />
            </div>
            <img src="@/assets/img/birds-hero.svg" alt="birds" class="birds_hero" />
        </section>

        <section class="text_img switch" v-if="pageIsLoaded">
            <div class="data">
                <h3 class="title medium" data-inview="fadeInUp" data-delay="200">
                    <span v-html="content.blockInformations1.titre"></span>
                </h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.blockInformations1.texte"></span>
                </p>
                <a
                    :href="content.blockInformations1.boutonUrl[$route.meta.lang]"
                    target="_blank"
                    class="cta regular-text"
                    data-inview="fadeInUp"
                    data-delay="400"
                    >{{ content.blockInformations1.bouton.customText }}</a
                >
            </div>
            <div class="img" data-inview="slideRight" data-delay="200">
                <img :src="content.blockInformations1.image.url" :alt="content.blockInformations1.image.titre" />
            </div>
            <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
        </section>

        <section class="approche_wrap mission" v-if="pageIsLoaded">
            <h2 class="title medium" data-inview="fadeInUp" data-delay="200">{{ content.entete2.titre }}</h2>
            <p class="regular-text intro" data-inview="fadeInUp" data-delay="300">
                <span v-html="content.entete2.texte"></span>
            </p>
            <div
                class="bloc"
                data-inview="fadeInUp"
                :data-delay="(2 + 1) + '00'"
                v-for="(item, i) in content.cartes1" :key="i"
            >
                <div class="line"></div>
                <p class="regular-text">
                    <span v-html="item.texte"></span>
                </p>
            </div>
            <a
                :href="content.boutonUrl[$route.meta.lang]"
                target="_blank"
                class="cta regular-text"
                >{{ content.bouton.customText }}</a
            >
            <img src="@/assets/img/texture-2.svg" alt="texture" class="texture" />
        </section>

        <section class="historique_wrap" v-if="pageIsLoaded && content.equipe1.length">
            <div class="content show fondation">
                <div class="photos">
                    <h3 class="title small">{{ content.titre1 }}</h3>
                    <div class="photo" v-for="(item, i) in content.equipe1" :key="i">
                        <div class="img"><img :src="item.image.url" :alt="item.image.titre" /></div>
                        <p class="regular-text name">{{ item.titre }}</p>
                        <p class="regular-text">{{ item.texte }}</p>
                    </div>
                </div>
                <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'
// import Flickity from 'flickity'

export default {
    name: 'Fondation',

    data() {
        return {
            // teamCarousel: null,
            maison: true,
        }
    },

    methods: {
        maisonh() {
            this.maison = true
        },
        maisonf() {
            this.maison = false
        },
        toogleNavBarStatus() {
            const st = document.documentElement.scrollTop

            if (!document.querySelector('.hero_block')) {
                return
            }

            if (st >= 75) {
                document.querySelector('.hero_block').classList.add('remove_margin_top')
            } else {
                document.querySelector('.hero_block').classList.remove('remove_margin_top')
            }
        },
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        // this.teamCarousel = new Flickity(document.querySelector('.main-carousel'), {
                        //     wrapAround: true,
                        //     imagesLoaded: true,
                        //     prevNextButtons: true,
                        //     pageDots: false,
                        // })

                        this.toogleNavBarStatus()
                        window.addEventListener('scroll', () => {
                            this.toogleNavBarStatus()
                        })
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>
